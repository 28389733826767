import React, { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import {
  QUERY_MYSOLVY_TENANT,
  IQueryMySolvyTenantData,
} from '../../graphql/tenant/queryMySolvyTenant'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import { HeaderContainer } from '../../components/Styles/CustomElementsStyled'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'

export interface MySolvyTenantRow {
  id: string
  name: string
  shortName: string
  active: boolean
  createdAt: string
}

const MySolvyTenantList = () => {
  const { t, i18n } = useTranslation(['tenant'])
  const { data, loading } = useQuery<IQueryMySolvyTenantData>(
    QUERY_MYSOLVY_TENANT,
    {
      fetchPolicy: 'no-cache',
    }
  )

  const csvHeaders = [
    { label: t('tenant'), key: 'name' },
    { label: t('domains'), key: 'domains' },
    { label: t('creation_date'), key: 'createdAtCSV' },
  ]

  const invisibleColumns = {
    createdAtCSV: false,
  }

  const columns: ColumnDef<MySolvyTenantRow, any>[] = useMemo(
    () => [
      {
        header: 'ID',
        accessorKey: 'id',
      },
      {
        header: t('name'),
        accessorKey: 'domains',
      },
      {
        header: t('short_name'),
        accessorKey: 'shortName',
      },
      {
        header: t('columns:creation_date'),
        accessorKey: 'createdAt',
        cell: (value) =>
          !!value && dateIso2localeString(value.getValue(), i18n.language),
        enableColumnFilter: false,
      },
      {
        header: t('active'),
        accessorKey: 'active',
      },
    ],
    [t, i18n]
  )

  const tableData: MySolvyTenantRow[] = useMemo(
    () =>
      data?.queryMySolvyTenant.map(
        ({ id, name, shortName, createdAt, active }) => ({
          id,
          name,
          shortName,
          createdAt,
          createdAt_csv: dateIso2ExcelFormat(createdAt),
          active,
        })
      ) || [],
    [data]
  )

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <HeaderContainer>
        <TypographyStyled variant="h6">
          {t('mysolvy_companies')}
        </TypographyStyled>
      </HeaderContainer>
      <Table
        columns={columns}
        data={tableData}
        // onRowClick={(rowData) => setTenantToEdit(rowData)}
        csvExport
        columnVisibility={invisibleColumns}
        enableRowSelection
        csvHeaders={csvHeaders}
        sortByKey="name"
      />
    </>
  )
}

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
`

export default MySolvyTenantList
