import gql from 'graphql-tag'

export interface IMySolvyTenant {
  id: string
  active: boolean
  createdAt: string
  name: string
  shortName: string
}

export interface IQueryMySolvyTenantData {
  queryMySolvyTenant: IMySolvyTenant[]
}

export const QUERY_MYSOLVY_TENANT = gql`
  query queryMySolvyTenant {
    queryMySolvyTenant {
      id: Id
      active: Active
      createdAt: Created
      name: FullName
      shortName: ShortName
    }
  }
`
